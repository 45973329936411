<template>
  <div>
    <title-bar :title="$t('navigations.articles')" />
    <div v-if="this.rows" class="custom-table">
      <table-actions
        :actions="['search', 'addBtn', 'perPage']"
        :addBtnText="$t('messages.articleNew')"
        :addBtnLink="$helper.getAddUrl('article')"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
        <template v-slot:id="data">
          <router-link :to="$helper.getEditUrl('article', data.value.id)">{{ data.value.id }}</router-link>
        </template>
        <template v-slot:name="data">
          <strong>{{
            data.value.name
          }}</strong>
        </template>


        <template v-slot:created="data">
          {{ $helper.formatDate(data.value.createdAt) }}
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">

            <edit-btn :path="$helper.getEditUrl('article', data.value.id)" />
            <delete-btn @pressDelete="deleteArticle(data.value['@id'])" />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";

import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";

import Toast from "@/components/Toast.vue";
import { useToast } from "vue-toastification";

export default {
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar,
  },
  data() {
    return {
      search: "",
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: '#',
          field: "id",
          sortable: false,
        },
        {
          label: this.$t('forms.name'),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t('forms.createdAt'),
          field: "created",
          sortable: false,
        },
        {
          label: this.$t('forms.actions'),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadArticles();
  },
  methods: {
    loadArticles() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,

      };
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Articles.getCollection({ params }, 'page_article_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadArticles();
    },
    deleteArticle(url) {
      this.$Articles
        .deleteResourceByUrl({ url })
        .then((response) => {
          if (response.status === 204) {
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              this.$t('messages.articleDeleted'),
              "",
              "success"
            );
            this.loadArticles();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$emit("clearAction");
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              error.response.data["hydra:title"],
              error.response.data["hydra:description"],
              "danger"
            );
          }
        });
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadArticles();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadArticles();
    },
    onPageChange() {
      this.loadArticles();
    },

  },
};
</script>

<style>
</style>
